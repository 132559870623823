export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  SEARCH_BOX_PLACEHOLDER: 'Search our store',

  SEARCH_BOX_BUTTON_CLASS: 'field__button',

  PRODUCT_REPEATER_CLASS: 'products',

  TOTAL_HITS: 'products',
  SORT_BY: 'Sort by',

  CURRENT_SEARCH: 'Shopping by',
  FILTERS_TEXT:
    '<span rt-if="this.selectedFacetsCount" class="cm_dialogButton__count"> {{selectedFacetsCount}}</span> Filter and sort',

  FACET_TITLE_ICON: `
  <rt-virtual rt-if="this.isCollapsed" template="icons/angle-down" />
  <rt-virtual rt-if="!this.isCollapsed" template="icons/angle-up" />`,

  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',

  GARAGE_SIZE: '<span class="garage-size cart-count-bubble with__count">{{size}}</span>',
};
