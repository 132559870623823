import shopifyGenericDefaults from '../shopify-generic/config.js';
import { addToCart, onContentClick } from './cart.js';

window.Convermax.addToCart = (e) => addToCart(e);

shopifyGenericDefaults.Widgets.find((w) => w.name === 'VerifyFitment').columnBreakpoint = 500;
shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBrandTiles').isVehicleWidgetDisabled = true;

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const cultureCode = globalThis.Shopify?.routes?.root.replaceAll('/', '') ?? '';

const baseInitFunc = shopifyGenericDefaults.InitFunc;

function InitFunc() {
  baseInitFunc?.();
  onContentClick();
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  defaultView: 'grid',
  SearchRequestDefaults: {
    ...shopifyGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === `/${cultureCode}`,
    },
  },
  pagination: {
    edgeRange: 0,
    centralRange: 2,
    disableDelimeters: true,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => w.name !== 'HeaderVehicleWidget'),
    {
      name: 'SearchBox_tab',
      type: 'SearchBox',
      location: { selector: '#cm-sku-tab', class: 'cm_search-box-root__tab' },
      template: 'SearchBox',
      disableDropdown: true,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-wheel-tab',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-tire-tab',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      fields:
        !!window.Convermax.config?.extraFieldsAsFilters && shopifyGenericDefaults.fitmentSearch.baseFields,
    },
  ],
};
